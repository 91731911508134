import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaShieldAlt, FaCode, FaShoppingCart, FaUniversity, FaBalanceScale, FaGraduationCap, FaBars } from "react-icons/fa";
import { FaNairaSign } from "react-icons/fa6";

const App = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  console.log(window.innerWidth);

  return (
    <div className="min-h-screen bg-black-900 text-white">
      {/* Hero Section */}
      <header className="text-center bg-cover bg-center text-white" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixid=M3wxOTgwNzN8MHwxfHNlYXJjaHwxfHxBSSUyMHRyYW5zZm9ybWF0aW9ufGVufDF8fHx8MTc0MTM1MjEzNHww&ixlib=rb-4.0.3&w=1200&q=25&auto=format')" }} id="about">
        {/* Navigation Bar */}
        <nav className={`flex justify-between items-center p-6 fixed w-full top-0 z-10 transition-all duration-300 ${(scrolling || menuOpen) ? "bg-gray-900 shadow-lg" : "bg-gray-800 opacity-70"}`}>
          <div className="text-2xl font-bold">Reinsys AI</div>
          <div className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
            <FaBars className="text-2xl cursor-pointer" />
          </div>
          <ul className={`md:flex space-x-6 absolute md:static top-16 text-left left-0 px-2 w-full bg-gray-800 md:bg-transparent md:w-auto flex-col md:flex-row ${menuOpen ? "flex" : "hidden"}`}>
            <li><a href="#about" className="block p-4 hover:text-blue-400">About</a></li>
            <li><a href="#value" className="block p-4 hover:text-blue-400">Value</a></li>
            <li><a href="#use-cases" className="block p-4 hover:text-blue-400">Use Cases</a></li>
            <li><a href="#contact" className="block p-4 hover:text-blue-400">Get Started</a></li>
          </ul>
        </nav>
        <div className="relative py-40 px-5 ">
          <div className="absolute inset-0 bg-black opacity-75 m-0"></div>
          <motion.h1 className="text-5xl font-bold drop-shadow-lg" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
            Powering Creativity with Artificial Intelligence
          </motion.h1>
          <motion.p className="text-lg mt-4 drop-shadow-lg" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
            On a mission to democratize high-impact AI solutions across Africa.
          </motion.p>
        </div>
      </header>

      {/* What is Reinsys AI? */}
      <motion.section className="py-16 px-8 bg-blue-950 flex flex-col md:flex-row items-center" id="about" initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <img src="https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1200/https://cdn.gamma.app/y4fzitrlujxik6p/generated-images/NbWdjHAAJS6bMZI_jjFSJ.jpg" alt="AI illustration" className="w-full md:w-1/2 rounded-lg" />
        <div className="mt-8 md:mt-0 md:ml-8 flex flex-col space-y-6">
          <h2 className="text-3xl font-bold">Enabling Seamless AI Transformation</h2>
          <motion.div className="p-6 bg-gray-700 rounded-lg" whileHover={{ scale: 1.05 }}>
            <FaCode className="text-6xl text-blue-300 mb-4" />
            <h3 className="text-xl font-semibold">Developer Studio</h3>
            <p className="mt-2">Provides easy-to-use tools for goverment and enterprises to enable their people create AI-powered tools, products and services.</p>
          </motion.div>
          <motion.div className="p-6 bg-gray-700 rounded-lg" whileHover={{ scale: 1.05 }}>
            <FaShieldAlt className="text-6xl text-blue-300 mb-4" />
            <h3 className="text-xl font-semibold">Integration Services</h3>
            <p className="mt-2">Facilitates seamless integration of AI solutions into your business operations and enterprise systems.</p>
          </motion.div>
          <motion.div className="p-6 bg-gray-700 rounded-lg" whileHover={{ scale: 1.05 }}>
            <FaBalanceScale className="text-6xl text-blue-300 mb-4" />
            <h3 className="text-xl font-semibold">Operationalization Support</h3>
            <p className="mt-2">Supports functional teams to design, validate, and bring transformative AI initiatives to live quickly and reliably.</p>
          </motion.div>
        </div>
      </motion.section>

      {/* Reinsys AI Value Propositions */}
      <motion.section className="py-0 px-0 text-center bg-cover bg-black bg-center" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixid=M3wxOTgwNzN8MHwxfHNlYXJjaHwxfHxidXNpbmVzcyUyMGdyb3d0aCUyMGNoYXJ0fGVufDF8fHx8MTc0MTI3MzY1Mnww&ixlib=rb-4.0.3&w=2400&q=25&auto=format')" }} id="value" initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <div className="relative py-16 px-8 ">
          <div className="absolute inset-0 bg-black opacity-75 m-0 p-0"></div>
          <h2 className="relative text-3xl text-white font-bold">Unique Value Propositions</h2>
          <div className="relative grid md:grid-cols-3 gap-8 mt-8">
            {valueProps.map((item, index) => (
              <motion.div key={index} className="p-6 bg-gray-700 opacity-90 rounded-lg" whileHover={{ scale: 1.05 }}>
                <item.icon className="text-6xl mb-4 text-blue-300 mx-auto" />
                <h3 className="text-xl font-semibold">{item.title}</h3>
                <p className="mt-2">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>


      {/* How Businesses Use Reinsys AI */}
      <motion.section className="py-16 px-8 text-center bg-gray-800" id="use-cases" initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <h2 className="text-3xl font-bold">How Businesses Use Reinsys AI</h2>
        <div className="grid md:grid-cols-2 gap-8 mt-8">
          {useCases.map((item, index) => (
            <motion.div key={index} className="p-6 bg-gray-700 rounded-lg" whileHover={{ scale: 1.05 }}>
              <item.icon className="text-6xl mb-4 text-blue-300 mx-auto" />
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <p className="mt-2">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* Start Delivering AI Transformation */}
      <section className="py-16 text-center bg-blue-950" id="contact">
        <h2 className="text-3xl font-bold">Start Delivering AI Transformation</h2>
        <p className="mt-4">Reinsys AI is transforming how organisations build and scale AI solutions.</p>
        <a href="https://demo.reinsys.ai" target="_blank" rel="noopener noreferrer">
          <button className="mt-6 px-6 py-3 bg-white text-blue-600 rounded-xl hover:bg-gray-200">Free Experimentation</button>
        </a>
        <a href="mailto:info@reinsys.com">
          <button className="mt-6 ml-4 px-6 py-3 bg-green-500 text-white rounded-xl hover:bg-green-600">Contact Us</button>
        </a>
      </section>

      {/* Footer */}
      <footer className="py-6 text-center bg-gray-900">
        <p>&copy; {new Date().getFullYear()} Reinsys. All rights reserved.</p>
      </footer>
    </div>
  );
};

const valueProps = [
  { icon: FaNairaSign, title: "Lower Cost", description: "Pay-per-use model reduces upfront investment and eliminates expensive R&D." },
  { icon: FaShieldAlt, title: "Reduced Risks", description: "Quickly test, validate, and refine AI solutions with real user feedback." },
  { icon: FaCode, title: "No Tech Barriers", description: "No-code tools for subject matter experts to build solutions for their business use cases." },
];

const useCases = [
  { icon: FaShoppingCart, title: "Social Commerce", description: "AI agents for SMEs to deliver products and services on social media." },
  { icon: FaUniversity, title: "Conversational Banking", description: "Automate banking tasks via AI-powered agents." },
  { icon: FaBalanceScale, title: "Regulatory Monitoring", description: "Automated regulatory compliance for highly regulated businesses in Africa." },
  { icon: FaGraduationCap, title: "Learning Support", description: "AI-powered education assistants for 24/7 learning support." },
];

export default App;
